import React, { useEffect } from "react";
import { useAuth } from "./authContext";
import { navigate } from "gatsby";

function PrivateRouteResolver({ children, location }) {
  const { isAuthenticated, checkAuthentication } = useAuth();
  const [mounted, setMounted] = React.useState(false);
  const [previousRoute, setPreviousRoute] = React.useState("/platform");

  const privateRoutes = ["/platform"];

  const authRoute = "/auth";

  useEffect(() => {
    if (mounted) {
      if (location.pathname.includes(privateRoutes)) {
        checkAuthentication();

        if (!isAuthenticated) {
          setPreviousRoute(location.pathname + location.search);
          navigate("/auth");
        }
      }
      if (location.pathname.includes(authRoute)) {
        checkAuthentication();

        if (isAuthenticated) {
          navigate(previousRoute);
        }
      }
    } else {
      checkAuthentication()
        .then(() => {
          setMounted(true);
        })
        .catch(() => {
          setMounted(true);
        });
    }
  }, [location, isAuthenticated, mounted]);

  return <>{children}</>;
}

export default PrivateRouteResolver;
